#event-search,
#project-search {
  background-color: #b7d6ef;
  border-radius: 0 0 13px 13px;
  margin-bottom: 26px;
  position: relative;
  z-index: 5;
}
#event-search[aria-hidden="true"],
#project-search[aria-hidden="true"] {
  display: none;
}
#event-search.no-results,
#project-search.no-results {
  background-color: #d43838;
}
#event-search__search,
#project-search__search {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
  background-color: #d8ecfe;
  border-radius: 0 0 13px 13px;
  padding: 26px;
  position: relative;
  z-index: 1;
}
#event-search__search h2,
#project-search__search h2 {
  color: #0080bc;
  text-transform: none;
}
#event-search__search-field-wrapper,
#project-search__search-field-wrapper {
  position: relative;
}
#event-search__search-field-wrapper [type="submit"],
#project-search__search-field-wrapper [type="submit"] {
  background: none;
  color: #7a7a7a;
  height: 26px;
  padding: 0;
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
}
#event-search__search-field-wrapper [type="submit"]:after,
#project-search__search-field-wrapper [type="submit"]:after {
  content: 's';
  display: block;
  font-family: 'Iconfont' !important;
  font-size: 18px;
  height: 100%;
  line-height: 26px;
  margin: 0;
  width: 100%;
}
#event-search__search-field-wrapper [type="submit"]:hover,
#project-search__search-field-wrapper [type="submit"]:hover {
  color: #000000;
}
#event-search__search-field,
#project-search__search-field {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  display: block;
  font-size: 18px;
  font-weight: normal;
  padding: 13px 65px 13px 26px;
  width: 100%;
  text-overflow: ellipsis;
}
#event-search__search-field::placeholder,
#project-search__search-field::placeholder {
  opacity: 0.7;
}
#event-search__search-field:focus::placeholder,
#project-search__search-field:focus::placeholder {
  color: transparent;
}
#event-search__search-field:focus-visible,
#project-search__search-field:focus-visible {
  outline: #0080bc solid 2px;
}
#event-search__search-field:hover,
#project-search__search-field:hover {
  border-color: #DBDADA;
  outline: none;
}
#event-search__no-results,
#project-search__no-results {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  background-color: #d43838;
  color: #ffffff;
  border-radius: 0 0 13px 13px;
  padding: 13px 26px;
  text-align: center;
}
#event-search__no-results[aria-hidden="true"],
#project-search__no-results[aria-hidden="true"] {
  display: none;
}
#event-search__toggle-extended-search,
#project-search__toggle-extended-search {
  appearance: none;
  background-color: transparent;
  border: none;
  color: #0080bc;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  margin-top: 26px;
  padding: 0;
  text-align: left;
}
#event-search__toggle-extended-search:before,
#project-search__toggle-extended-search:before {
  align-items: center;
  background-color: #0080bc;
  border-radius: 3px;
  box-sizing: border-box;
  color: #ffffff;
  content: '+';
  display: inline-flex;
  font-family: 'Iconfont';
  font-size: 12px;
  height: 18px;
  justify-content: center;
  line-height: 1;
  margin-right: 8px;
  vertical-align: 2px;
  width: 18px;
}
#event-search__toggle-extended-search[aria-expanded="true"]:before,
#project-search__toggle-extended-search[aria-expanded="true"]:before {
  content: '-';
}
#event-search__extended-search,
#project-search__extended-search {
  background-color: #b7d6ef;
  border-radius: 0 0 13px 13px;
  padding: 26px;
}
#event-search__extended-search[aria-hidden="true"],
#project-search__extended-search[aria-hidden="true"] {
  display: none;
}
#event-search__filters {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 26px;
}
#project-search__filters {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 26px;
}
#project-search__buttons,
#event-search__buttons {
  display: flex;
  flex-flow: row wrap;
  gap: 52px;
  margin-top: 26px;
  justify-content: space-between;
}
#project-search__buttons #event-search__reset,
#event-search__buttons #event-search__reset,
#project-search__buttons #project-search__reset,
#event-search__buttons #project-search__reset {
  padding-right: 13px;
}
#toggle-event-search-form,
#toggle-project-search-form {
  text-transform: uppercase;
}
.filterbox__options li.indirect-click label:after {
  opacity: 0.5;
}
@media screen and (max-width: 985px) {
  #event-search__filters,
  #project-search__filters {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  #event-search__filters,
  #project-search__filters {
    grid-template-columns: 1fr;
  }
  #event-search__buttons,
  #project-search__buttons {
    gap: 26px;
  }
}
