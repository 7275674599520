@import "_globals";

// BASE
// ___________________________________________________________________

#event-search,
#project-search {
    background-color: @c-lightblue-260;
    border-radius: 0 0 13px 13px;
    margin-bottom: 26px;
    position: relative;
    z-index: 5;

    &[aria-hidden="true"] {
        display: none;
    }

    &.no-results {
        background-color: @c-red;
    }
}

// SEARCH FIELD
// ___________________________________________________________________

#event-search__search,
#project-search__search {
    .mx-shadow-lg();
    background-color: @c-lightblue-400;
    border-radius: 0 0 13px 13px;
    padding: 26px;
    position: relative;
    z-index: 1;

    h2 {
        color: @c-primary-1;
        text-transform: none;
    }
}

#event-search__search-field-wrapper,
#project-search__search-field-wrapper {
    position: relative;

    [type="submit"] {
        background: none;
        color: @c-gray-300;
        height: 26px;
        padding: 0;
        position: absolute;
        right: 26px;
        top: 50%;
        transform: translateY(-50%);
        width: 26px;

        &:after {
            content: 's';
            display: block;
            font-family: 'Iconfont' !important;
            font-size: 18px;
            height: 100%;
            line-height: 26px;
            margin: 0;
            width: 100%;
        }

        &:hover {
            color: @c-black;
        }
    }
}

#event-search__search-field,
#project-search__search-field {
    .mx-shadow-md();
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    font-size: 18px;
    font-weight: normal;
    padding: 13px 65px 13px 26px;
    width: 100%;
    text-overflow: ellipsis;

    &::placeholder {
        opacity: 0.7;
    }

    &:focus::placeholder {
        color: transparent;
    }

    &:focus-visible {
        outline: @c-blue-200 solid 2px;
    }

    &:hover {
        border-color: @c-gray-450;
        outline: none;
    }
}

// NO RESULTS
// ___________________________________________________________________

#event-search__no-results,
#project-search__no-results {
    .mx-shadow-md();
    background-color: @c-red;
    color: @c-white;
    border-radius: 0 0 13px 13px;
    padding: 13px 26px;
    text-align: center;

    &[aria-hidden="true"] {
        display: none;
    }
}

// EXTENDED SEARCH
// ___________________________________________________________________

#event-search__toggle-extended-search,
#project-search__toggle-extended-search {
    appearance: none;
    background-color: transparent;
    border: none;
    color: @c-primary-1;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    margin-top: 26px;
    padding: 0;
    text-align: left;

    &:before {
        align-items: center;
        background-color: @c-primary-1;
        border-radius: 3px;
        box-sizing: border-box;
        color: @c-white;
        content: '+';
        display: inline-flex;
        font-family: 'Iconfont';
        font-size: 12px;
        height: 18px;
        justify-content: center;
        line-height: 1;
        margin-right: 8px;
        vertical-align: 2px;
        width: 18px;
    }

    &[aria-expanded="true"] {
        &:before {
            content: '-';
        }
    }
}

#event-search__extended-search,
#project-search__extended-search {
    background-color: @c-lightblue-260;
    border-radius: 0 0 13px 13px;
    padding: 26px;

    &[aria-hidden="true"] {
        display: none;
    }
}

#event-search__filters {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 26px;
}

#project-search__filters {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 26px;
}

#project-search__buttons,
#event-search__buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 52px;
    margin-top: 26px;
    justify-content: space-between;

    #event-search__reset,
    #project-search__reset {
        padding-right: 13px;
    }
}

#toggle-event-search-form,
#toggle-project-search-form {
    text-transform: uppercase;
}

.filterbox__options li.indirect-click label:after {
    opacity: 0.5;
}

// ***********************************************************************
// MEDIA QUERY: tablet portrait
// ***********************************************************************
@media screen and (max-width: 985px) {
    #event-search__filters,
    #project-search__filters {
        grid-template-columns: repeat(2, 1fr);
    }
}

// ***********************************************************************
// MEDIA QUERY: smartphone landscape
// ***********************************************************************
@media screen and (max-width: 767px) {
    #event-search__filters,
    #project-search__filters {
        grid-template-columns: 1fr;
    }

    #event-search__buttons,
    #project-search__buttons {
        gap: 26px;
    }
}